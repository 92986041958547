// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';
import "./App.css";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          {/*<Settings />*/}
          <ScrollToTop />
          <Router />
          </ NotistackProvider>

        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

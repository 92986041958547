import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';

// material
import { Box, BoxProps, Card, Container } from '@mui/material';

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
  fullWidth?: boolean;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', fullWidth, ...other }, ref) => {

  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>FUTSTAT - {title}</title>
      </Helmet>
      <Container disableGutters={fullWidth ?? false} maxWidth={false}>
        <Card sx={{ p: 5, minHeight: 950, mt: 3, mb: 3, borderRadius: 2 }}>
          {children}
        </Card>
      </Container>
    </Box>
  );
});

export default Page;

// material
import { Stack } from '@mui/material';

// components
import Page from '../../components/Page';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Loading } from '../../components/Loading';
import { UpdatePlayerCompetitionRegisterCriteria } from '../../apis/data-contracts';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_MANAGER } from '../../routes/paths';
import { MiscellaneousReducerState } from '../../redux/reducers/miscellaneousReducer';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PlayerForm } from './components/PlayerForm';
import { Icon } from '@iconify/react';
import { MButtonGreyWithText } from '../../components/@material-extend';
import { usePlayerService } from '../../services/api/player/PlayerService';
import { useCompetitionService } from '../../services/api/competition/CompetitionService';



export default function PlayerEdit() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [playerEmail, setPlayerEmail] = useState<string>('');
  const [playerName, setPlayerName] = useState<string>('');
  const [searchParams] = useSearchParams(); 
  const navigate = useNavigate();

  const { getCompetitionTeamPlayerById } = useCompetitionService();
  
  const misc = useSelector((state: { miscellaneous: MiscellaneousReducerState }) => state?.miscellaneous);
  const { getPlayerById } = usePlayerService();
  
  const [initialPlayerValues, setInitialPlayerValues] = useState<UpdatePlayerCompetitionRegisterCriteria>();

  const [defaultIsPlayer, setDefaultIsPlayer] = useState<string>('Sim');


  const handleGetIsTechnicalPosition = (position: string) => {
    if(position === 'Preparador Goleiro' || position === 'Auxiliar Técnico' || position === 'Supervisor' || position === 'Preparador Físico' || position === 'Massagista' || position === 'Analista Desempenho' || position === 'Fisioterapeuta' || position === 'Técnico' || position === 'Médico' || position === 'Representante') {
      return true
    }
    return false
  }


  const getPlayer = async (playerId: number) => {
    setLoading(true);

    await getPlayerById(playerId).then( async (data) => {
      setPlayerName(data.result?.name ?? '')
      setPlayerEmail(data.result?.email ?? '')

      getCompetitionTeamPlayerById({id: misc.selectedCompetition?.id ?? 0, teamId: misc.selectedTeam?.id ?? 0, playerId: playerId}).then(data2 => {

        setInitialPlayerValues({
          playerId: data.result?.id,  
          nickName: data.result?.nickname ?? '',
          doc: data.result?.doc ?? '',
          docCbf: data.result?.docCbf ?? '',
          docCbfs: data.result?.docCbfs ?? '',
          docFederacao: data.result?.docFederacao ?? '',
          birthday: data.result?.birthday ?? '',
          playerNumberDefault: data.result?.playerNumberDefault ?? '',
          docCpf: data.result?.docCpf ?? '',
          competitionId: misc.selectedCompetition?.id,
          teamId: misc.selectedTeam?.id,
          coach: data2.result?.coach,
          position: data2.result?.position
        });
        handleGetIsTechnicalPosition(data2.result?.position ?? '') ? setDefaultIsPlayer('Não') : setDefaultIsPlayer('Sim');
      }).catch( (error) => {

        setInitialPlayerValues({
          playerId: data.result?.id,  
          nickName: data.result?.nickname ?? '',
          doc: data.result?.doc ?? '',
          docCbf: data.result?.docCbf ?? '',
          docCbfs: data.result?.docCbfs ?? '',
          docFederacao: data.result?.docFederacao ?? '',
          birthday: data.result?.birthday ?? '',
          playerNumberDefault: data.result?.playerNumberDefault ?? '',
          docCpf: data.result?.docCpf ?? '',
          competitionId: misc.selectedCompetition?.id,
          teamId: misc.selectedTeam?.id,
        });
      });
      setLoading(false);
    }).catch(() => {

      setLoading(false);
    })
  }

  useEffect(() => {
    if(misc.selectedPlayer?.playerId) getPlayer(misc.selectedPlayer.playerId);
    else navigate(PATH_MANAGER.competition.team.player.list);
  }, []);
  
  return (
    <Loading loading={loading}>
      <Page title={t(`competitionManager:editPlayer`)} fullWidth>

        <Stack alignItems={'flex-start'} justifyContent={'space-between'} direction={'row'} mb={5}>
          <MButtonGreyWithText onClick={() => {navigate(PATH_MANAGER.competition.team.player.list) }} startIcon={(<Icon icon={"system-uicons:backward"} width={25} height={25}/>)}>
            {t('competitionManager:back')}
          </MButtonGreyWithText>
        </Stack>

        <HeaderBreadcrumbs
          heading={`${t('competitionManager:editPlayer')}: ${playerName}`}
          links={[
            {
              name: `${t('competitionManager:competition')}: ${misc.selectedCompetitionGroup?.name}`,
              icon: <Icon icon={'mdi:tournament'}/>,
              href: PATH_MANAGER.competitionGroups.root
            },
            {
              name: `${t('competitionManager:championship')}: ${misc.selectedCompetition?.name}`,
              icon: <Icon icon={'bi:trophy'}/>,
              href: PATH_MANAGER.competition.list
            },
            {
              name: `${t('competitionManager:teams')} - ${misc.selectedTeam?.name}`,
              icon: <Icon icon={'ri:shield-line'}/>,
              href: PATH_MANAGER.competition.team.list
            },
            {
              name: t('competitionManager:players'),
              icon: <Icon icon={'carbon:soccer'}/>,
              href: PATH_MANAGER.competition.team.player.list
            },
            { name: t('competitionManager:editPlayer') }
          ]}
        />
        <PlayerForm  
          defaultIsPlayer={defaultIsPlayer}
          setDefaultIsPlayer={setDefaultIsPlayer}          
          initialPlayerValues={initialPlayerValues} 
          setLoading={(value: boolean) => setLoading(value)} 
          playerName={playerName} 
          playerEmail={playerEmail} 
          formMode='Edit' 
        />
      </Page>
    </Loading>
  );
}


import { AccessRoleType, PlayerViewModel, UserViewModel } from "../../apis/data-contracts";

export type AuthReducerState = UserViewModel & {
  loggedIn?: boolean;
  isRegistrationOk: boolean;
  organizationId?: number;
};

const initialState: AuthReducerState = {
  loggedIn: false,
  isRegistrationOk: false,
  token: undefined,
  login: undefined,
  name: undefined,
  id_Login: undefined,
  player: undefined,
  roles: undefined,
  organizationId: undefined
};

const authReducer = (
  state: AuthReducerState = initialState,
  action: any
): AuthReducerState => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        ...action.data,
        loggedIn: true,
      };
    }
    case "LOGOUT": {
      return {
        ...initialState,
        loggedIn:false
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;

import { Theme } from '@mui/material/styles';
import { fontSize } from '@mui/system';

// ----------------------------------------------------------------------

export default function Table(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: isLight ? theme.palette.grey[300] : theme.palette.grey[900],
          },
          '&.MuiTableRow-hover:hover': {
            backgroundColor: isLight ? theme.palette.grey[300] : theme.palette.grey[900],
          },
          '&:nth-of-type(even)': {
            backgroundColor: isLight ? theme.palette.grey[200] : "#333D49"
          },
          '&:nth-of-type(odd)': {
            backgroundColor: isLight ? '#fff' : theme.palette.grey[800]
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
          borderBottom: 'none',
          fontSize: 10
        },
        head: {
          color: theme.palette.text.secondary,
          fontSize: 12,
          backgroundColor: theme.palette.background.neutral,
          '&:first-of-type': {
            // paddingLeft: theme.spacing(3),
            // borderTopLeftRadius: theme.shape.borderRadius,
            // borderBottomLeftRadius: theme.shape.borderRadius,
            // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`
          },
          '&:last-of-type': {
            // paddingRight: theme.spacing(3),
            // borderTopRightRadius: theme.shape.borderRadius,
            // borderBottomRightRadius: theme.shape.borderRadius,
            // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`
          }
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        },
        body: {
          '&:first-of-type': {
            // paddingLeft: theme.spacing(3)
          },
          '&:last-of-type': {
            // paddingRight: theme.spacing(3)
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}

import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
export type notificationReducerState = {
    title?: string;
    description?: string;
    instruction?: string;
    dateRange?: RangeInput<Date | string>
};

const initialState: notificationReducerState = {
    title: undefined,
    description: undefined,
    instruction: undefined,
    dateRange: undefined,

};

const notificationReducer = (
    state: notificationReducerState = initialState,
    action: any
): notificationReducerState => {
    switch (action.type) {
        case "CREATE_NOTIFICATION": {
            return {
                ...state,
                ...action.data,
                assessmentCreated: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default notificationReducer;

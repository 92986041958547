import { useAuthAction } from "../redux/actions/authAction";
import { useSelector } from "react-redux";
import { AuthReducerState } from "../redux/reducers/authReducer";
import { DateTime } from "luxon";
import axios from 'axios'
import { Api } from '../apis/Api';
//import { useUserAction } from "../redux/actions/userAction";
import { useSnackbar } from "notistack";
import { UpdatePasswordCriteria, UserViewModel, UserViewModelIResult, VerifyCodeCriteria } from "../apis/data-contracts";
import { useUserService } from "./api/user/UserService";
import { useMiscellaneousAction } from "../redux/actions/miscellaneousAction";
import { getTokenDecodedInfo } from "../utils/jwt";

export const useAuthService = () => {
  const api = new Api({ baseURL: `${process.env.REACT_APP_COMPETITIONMANAGERAPI_BASEURL}` });
  const { loginAction, logoutAction } = useAuthAction();
  const { miscellaneousAction } = useMiscellaneousAction();
  const { loggedIn } = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  //const { fetchUserData, clearUserData } = useUserAction();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { checkRegistrationStatus } = useUserService();

  return {
    login: async (login: string, password: string): Promise<AuthReducerState> => {
      await localStorage.removeItem('persist:root');

      return await api.v1LoginCreate({ login: login, password: password }).then(async (authData) => {
        let allInfos = await checkRegistrationStatus(authData?.data?.result?.id_Login ?? 0, authData?.data?.result?.token ?? '');
        
        allInfos = {...authData?.data?.result,
          player: allInfos.player,
          isRegistrationOk: allInfos.isRegistrationOk
        };

        let jwtDecodedInfos = getTokenDecodedInfo(allInfos.token ?? '');
        allInfos = {...allInfos,
          organizationId: jwtDecodedInfos.organization
        };
        await loginAction(allInfos);

        return allInfos ?? {};
      }).catch(async (err) => {
        if(err?.response?.data?.message)
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
            autoHideDuration: 3000, className: "snackbar" 
          });

        throw err;
      });
    },

    logout: async (): Promise<void> => {
      await localStorage.removeItem('persist:root')
      //clearUserData()
      logoutAction()
      miscellaneousAction({selectedCompetitionGroup: {}});
    },

    forgotPassword: async (login: string): Promise<UserViewModel | undefined> => {
      await localStorage.removeItem('persist:root');
      return await api.v1LoginForgotPasswordCreate({ login: login}).then(async (resultData) => {
        return resultData?.data?.result
      }).catch((err) => {
        if(err?.response?.data?.message)
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
            autoHideDuration: 3000, className: "snackbar" 
          });

        throw err;
      });
    },

    resendCode: async (loginId: number): Promise<UserViewModel | undefined> => {
      return await api.v1LoginResendcodeCreate(loginId).then(async (resultData) => {
        return resultData?.data?.result
      }).catch((err) => {
        if(err?.response?.data?.message)
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
            autoHideDuration: 3000, className: "snackbar" 
          });

        throw err;
      });
    },

    verifyCode: async (verifyCode: VerifyCodeCriteria): Promise<UserViewModel | undefined> => {
      return await api.v1LoginVerifycodeCreate(verifyCode).then(async (resultData) => {
        return resultData?.data?.result
      }).catch((err) => {
        if(err?.response?.data?.message)
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
            autoHideDuration: 3000, className: "snackbar" 
          });

        throw err;
      });
    },

    changePassword: async (updatePasswordValues: UpdatePasswordCriteria): Promise<UserViewModel | undefined> => {
      return await api.v1LoginUpdatepasswordCreate(updatePasswordValues).then(async (resultData) => {
        return resultData?.data?.result
      }).catch((err) => {
        if(err?.response?.data?.message)
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
            autoHideDuration: 3000, className: "snackbar" 
          });

        throw err;
      });
    },

    isAuthenticated: loggedIn
  }
}

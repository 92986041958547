import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { SxProps } from "@mui/system";

export type ButtonOnlyIconProps = {
  sx?: SxProps;
  icon: string;
  onClick?: () => void;
  other?: any;
}

export default function ButtonOnlyIcon({ icon, sx, onClick, ...other }: ButtonOnlyIconProps) {
  return(
    <IconButton sx={{ ...sx, backgroundColor: '#562B7F', '&:hover': {backgroundColor: '#3d1d5c'} }} {...other} onClick={onClick}>
      <Icon icon={icon} width={35} height={35} color={'white'}/>
    </IconButton>
  );
}

import { useSelector } from 'react-redux';
import { Api } from '../../../apis/Api';
import { AuthReducerState } from '../../../redux/reducers/authReducer';
import { UpdateUserCriteria, UserViewModel, UserViewModelIResult } from '../../../apis/data-contracts';
import { useSnackbar } from 'notistack';
import { useAuthAction } from '../../../redux/actions/authAction';
import { format } from 'date-fns';

export const useUserService = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { loginAction } = useAuthAction();
    const api = new Api({ baseURL: `${process.env.REACT_APP_COMPETITIONMANAGERAPI_BASEURL}` });

    const auth = useSelector((state: { auth: AuthReducerState }) => state?.auth);
    const requestParams = {
        headers: {
            "Authorization": `Bearer ${auth.token}`,
        }
    };

    return  {
        checkRegistrationStatus: async (loginId: number, validToken: string): Promise<AuthReducerState> =>
            await api.v1UserCheckRegistrationStatusCreate(loginId, {headers: {"Authorization": `Bearer ${validToken}`}})
            .then((registrationStatus: any) => {
                let registrationStatusFormated: UserViewModelIResult = {};

                //If first condition is true, it means that the api return was an error, so we need to handle this error because it doesn't returns the same object type of when it return code 200 (Success)
                //So we need to convert the api result to the type we want (the second if is for result verification when the api returns a code 200)
                if(registrationStatus?.response?.data?.status > 0)
                  registrationStatusFormated = registrationStatus?.response?.data as UserViewModelIResult;
                else if (registrationStatus.status > 0)
                  registrationStatusFormated = registrationStatus?.data as UserViewModelIResult;    
                
                //We receive a date without hours and our datePicker understand that it is 00:00:00 UTC+0, 
                //when datePicker converts it to brazil timezone to show, it removes 3 hours and shows the day before the one we want (dd-1/mm/yyyy 21:00:00 GMT/UTC+0)
                //we need to add 3 hours so the DatePicker will convert it and will shows the day we want
                if(!!registrationStatusFormated.result?.player?.birthday){
                  let dateToFormat = new Date(format(new Date(registrationStatusFormated.result.player.birthday), 'yyyy-MM-dd'));
                  dateToFormat.setHours(dateToFormat.getHours() + 3)
                  registrationStatusFormated.result.player.birthday = dateToFormat.toString();
                }
        
                let allAuthInfos:AuthReducerState = {
                  ...registrationStatusFormated?.result ?? {},
                  isRegistrationOk: registrationStatusFormated?.status == 200
                };
        
                return allAuthInfos;
              }).catch((registrationStatus) => {
                if(registrationStatus?.response?.data?.message)
                    enqueueSnackbar(registrationStatus.response.data.message, {
                    variant: 'error',
                    autoHideDuration: 3000, className: "snackbar" 
                    });
                
                let registrationStatusFormated: UserViewModelIResult = {};

                registrationStatusFormated = registrationStatus?.response?.data as UserViewModelIResult;                
                
                //We receive a date without hours and our datePicker understand that it is 00:00:00 UTC+0, 
                //when datePicker converts it to brazil timezone to show, it removes 3 hours and shows the day before the one we want (dd-1/mm/yyyy 21:00:00 GMT/UTC+0)
                //we need to add 3 hours so the DatePicker will convert it and will shows the day we want
                if(!!registrationStatusFormated.result?.player?.birthday){
                    let dateToFormat = new Date(registrationStatusFormated.result.player.birthday)
                    dateToFormat.setHours(dateToFormat.getHours() + 3)
                    registrationStatusFormated.result.player.birthday = dateToFormat.toString();
                }

                let allAuthInfos:AuthReducerState = {
                  ...registrationStatusFormated?.result ?? {},
                  isRegistrationOk: false};        
        
                return allAuthInfos;
              }),

        updateBasicInfos: async (updateUserCriteria: UpdateUserCriteria, validToken?: string): Promise<UserViewModelIResult> => {
            return await api.v1UserUpdateBasicInfosUpdate(updateUserCriteria, !!validToken ? {headers: {"Authorization": `Bearer ${validToken}`,}} : requestParams)
                .then(async (resp) => {
                    return resp?.data
                })
                .catch(async (err) => {
                    console.log(err.response);
                    let errors = Array(err?.response?.data?.validationErros).join('\n');

                    if(err?.response?.data?.message || errors !== '')
                        enqueueSnackbar((errors !== '' ? errors : err.response.data.message), {
                        variant: 'error',
                        autoHideDuration: 3000, className: "snackbar" 
                        });
                    throw err;
                })
            }
    }
}
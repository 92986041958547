import { Link as LinkComponent, LinkProps} from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import { ThemeReducerState } from "../../redux/reducers/themeReducer";
import { useSelector } from "react-redux";

interface LinkCompenentProps extends LinkProps {
    component?: typeof RouteLink;
    to?: string;
    state?: any;
}

export default function Link({ children, ...other }: LinkCompenentProps) {
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state?.theme);
  return(
    <LinkComponent
        sx={{...other.sx, color: selectedTheme === "dark" ? 'white' : '#562B7F'}}
        {...other}
        underline="none">
            {children}
    </LinkComponent>
  );
}
import { useDispatch } from "react-redux";
import { MiscellaneousReducerState } from "../reducers/miscellaneousReducer";
import { ManagementMode, Type } from "../../pages/competition/enum/CompetitionEnums";
import { LanguageReducerState } from "../reducers/languageReducer";
import { getEnumValueByKeyString } from "../../utils/enums";

export function useMiscellaneousAction() {
  const dispatch = useDispatch();

  return {
    miscellaneousAction: (miscellaneousReducerState: MiscellaneousReducerState) => {
      dispatch({
        type: "selectedCompetitionGroup",
        data: {selectedCompetition: {}, selectedTeam: {}, selectedPlayer: {} , selectedMatch: {}, selectedCompetitionGroup: miscellaneousReducerState.selectedCompetitionGroup}
      });
    },
    miscellaneousSaveSelectedCompetitionAction: (miscellaneousReducerState: MiscellaneousReducerState) => {
      dispatch({
        type: "selectedCompetition",
        data: {
          selectedTeam: {}, 
          selectedCompetition: {
            ...miscellaneousReducerState.selectedCompetition, 
            selectedManagementMode: (miscellaneousReducerState.selectedCompetition?.type1 === Type.Groups 
                    && (miscellaneousReducerState.selectedCompetition.type3 === Type.NaoSeAplica 
                      || miscellaneousReducerState.selectedCompetition.type4 === Type.NaoSeAplica)) 
              ? ManagementMode.Manual
              : ManagementMode.System}
        }
      });
    },
    miscellaneousSaveSelectedTeamAction: (miscellaneousReducerState: MiscellaneousReducerState) => {
      dispatch({
        type: "selectedTeam",
        data: {selectedTeam: miscellaneousReducerState.selectedTeam}
      });
    },
    miscellaneousSaveSelectedMatchAction: (miscellaneousReducerState: MiscellaneousReducerState) => {
      dispatch({
        type: "selectedMatch",
        data: {selectedMatch: miscellaneousReducerState.selectedMatch}
      });
    },
    miscellaneousSaveSelectedPlayerAction: (miscellaneousReducerState: MiscellaneousReducerState) => {
      dispatch({
        type: "selectedPlayer",
        data: {selectedPlayer: miscellaneousReducerState.selectedPlayer}
      });
    },
  };
}

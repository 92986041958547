import { Icon } from "@iconify/react";
import { Button, ButtonProps, Tooltip} from "@mui/material";
import { SxProps } from "@mui/system";
import { Link } from "react-router-dom";

interface ButtonGreyWithTextProps extends ButtonProps {
  component?: typeof Link;
  to?: string;
  state?: any;
  tooltipText?: ''
}

export default function ButtonGreyWithText({ children, tooltipText, ...other }: ButtonGreyWithTextProps) {
  return(
    <Tooltip title={tooltipText ?? ''}>
      <Button sx={{...other.sx, backgroundColor: '#f8f2fe', color: '#562B7F','&:hover': {backgroundColor:'#562B7F', color: '#f8f2fe'}}}
        {...other}
        variant={other.variant ?? 'contained'}
        onClick={other.onClick}>
          {children}
      </Button>
    </Tooltip>
  );
}
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from '@iconify/react';
import { Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound/SearchNotFound";
import { CompetitionMatchsOverviewViewModel, MatchOccurenceOverviewViewModel } from "../../../apis/data-contracts";
import { useTranslation } from 'react-i18next';

export type MatchSummaryGoalsListProps = {
  loading: boolean;
  matchOverview: CompetitionMatchsOverviewViewModel | undefined;
  allGoals: MatchOccurenceOverviewViewModel[];
  idTeamHome: number;
}

export default function MatchSummaryGoalsList (props : MatchSummaryGoalsListProps) {
  const { t } = useTranslation();

  return (      
    <>
      <Grid style={{marginTop: 50}}>
        <Typography textAlign={"center"} variant="h3">
          {t('competitionManager:summaryOccurences:scoredGoals')}
        </Typography>
        <Typography textAlign={"center"} variant="h6">
        {(t('competitionManager:summaryOccurences:subtitleDescription'))}
        </Typography>
      </Grid>
      <Grid style={{marginTop: 50, marginBottom: 10}}>
        <Typography textAlign={"center"} variant="h5">
          {props.matchOverview?.teamHomeName} Vs {props.matchOverview?.teamAwayName}
        </Typography>
      </Grid>
      <Grid style={{marginTop: 10, marginBottom: 30}}>
        <Typography textAlign={"center"} variant="h5">
          {props.allGoals.filter(x => x.teamId === props.idTeamHome).length} X {props.allGoals.filter(x => x.teamId !== props.idTeamHome).length}
        </Typography>
      </Grid>

        <TableContainer sx={{height: 300, overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:period')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:periodTime')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:playerPosition')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:playerNumber')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:playerName')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:action')}
                  </TableCell>


                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:gameHour')}
                  </TableCell>


                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:action')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:playerName')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:playerNumber')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:playerPosition')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:periodTime')}
                  </TableCell>
                  <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                    {t('competitionManager:summaryOccurences:period')}
                  </TableCell>


                </TableRow>
              </TableHead>
              {props.matchOverview && !props.loading ? (
                <TableBody>
                  { props.allGoals.length > 0 ? (
                  <>
                    {props.idTeamHome && props.allGoals.map((goal, i) => (
                      <TableRow hover key={`home-goal-${goal.playerId}-${goal.teamId}-${goal.gameTime}-${i}`}>
                      {
                        goal.teamId === props.idTeamHome ? (
                          <>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.phase ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.gameTime ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.playerPosition ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.playerNumber ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.playerNickName ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.type ?? "--"}
                            </TableCell>

                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                              {goal.occurrenceTime ?? "--"}     
                            </TableCell>

                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                          </>
                        ):(
                          <>                              
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            </TableCell>          
    
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.occurrenceTime ?? "--"}                                  
                            </TableCell>
    
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.type ?? "--"}
                            </TableCell>

                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.playerNickName ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.playerNumber ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.playerPosition ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.gameTime ?? "--"}
                            </TableCell>
                            <TableCell align="left" sx={{ fontSize: '0.700rem' }}>
                            {goal.phase ?? "--"}
                            </TableCell>          
                          </>
                        )
                      }
                      </TableRow>
                    ))}
                  </>
                  ):(
                    <TableRow hover><TableCell colSpan={13} align='center'>{t("competitionManager:noResultsFound")}</TableCell></TableRow>
                  )
                  }

                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={14}>
                      {props.loading ? (
                        <CircularProgress />
                      ) : (
                        <SearchNotFound
                          icon={<Icon icon={plusFill} />}
                          addButton={false}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
        </TableContainer>
    </>
  );
}

import { CompetitionGroupViewModel, CompetitionMatchsOverviewViewModel, CompetitionTeamPlayerSubscribeViewModel, TeamViewModel } from "../../apis/data-contracts";
import { CompetitionStateViewModel } from "../../pages/competition/components/CompetitionForm";

export type MiscellaneousReducerState ={
  selectedCompetitionGroup?: CompetitionGroupViewModel;
  selectedCompetition?: CompetitionStateViewModel;
  selectedTeam?: TeamViewModel;
  selectedMatch?: CompetitionMatchsOverviewViewModel;
  selectedPlayer?: CompetitionTeamPlayerSubscribeViewModel;
  loading?: boolean;
};

const initialState: MiscellaneousReducerState = {
  selectedCompetitionGroup: {},
  selectedCompetition: {},
  selectedTeam: {},
  selectedMatch: {},
  selectedPlayer: {}
};

const miscellaneousReducer = (
  state: MiscellaneousReducerState = initialState,
  action: any
): MiscellaneousReducerState => {
  switch (action.type) {
    case "selectedCompetitionGroup": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "selectedCompetition": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "selectedTeam": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "selectedMatch": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "selectedPlayer": {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default miscellaneousReducer;

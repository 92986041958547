import { useSelector } from 'react-redux';
import { Api } from '../../../apis/Api';
import { AuthReducerState } from '../../../redux/reducers/authReducer';
import { ApproveStatusMatchCriteria, CreateMatchCriteria, MatchCriteria, MatchOverviewViewModel, MatchViewModel, MatchViewModelIResult, V1MatchsGenerateDraftGroupDetailParams } from '../../../apis/data-contracts';
import { useSnackbar } from 'notistack';
import { useAuthAction } from '../../../redux/actions/authAction';
import { AxiosResponse } from 'axios';
import qs from 'querystring';

export const useMatchService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loginAction } = useAuthAction();
  const api = new Api({
    baseURL: `${process.env.REACT_APP_COMPETITIONMANAGERAPI_BASEURL}`,
  });

  const auth = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  const requestParams = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };
  return {
    saveMatch: async (
      query: CreateMatchCriteria
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsCreate(query, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    cancelMatch: async (
      matchId: number
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsCancelUpdate(matchId, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    approveMatch: async (
      data: ApproveStatusMatchCriteria
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsApproveUpdate(data, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    restoreMatch: async (
      matchId: number
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsRestoreUpdate(matchId, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    setPlaceAndDatetimeUpdate: async (
      matchId: MatchCriteria
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsSetPlaceAndDatetimeUpdate(matchId, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    updateMatch: async (
      query: CreateMatchCriteria
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsUpdate(query, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.validationErros)
            enqueueSnackbar(
              Array(err.response.data.validationErros).join('\n'),
              {
                variant: 'error',
                autoHideDuration: 3000, className: "snackbar" 
              }
            );

          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    getMatchPhases: async (): Promise<string[] | undefined | null> => {
      return await api
        .v1MatchsPhasesList(requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    getMatchGroups: async (
      idCompetition: number
    ): Promise<string[] | null | undefined> => {
      return await api
        .v1MatchsGroupsDetail(idCompetition, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    getMatchRounds: async (
      idCompetition: number
    ): Promise<string[] | null | undefined> => {
      return await api
        .v1MatchsRoundsDetail(idCompetition, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    generateDraft: async (
      idCompetition: number
    ): Promise<MatchViewModel[] | null | undefined> => {
      return await api
        .v1MatchsGenerateDraftDetail(idCompetition, requestParams)
        .then(async (resp) => {
          return resp?.data.result as MatchViewModel[];
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    saveDraft: async (
      criteria: CreateMatchCriteria[]
    ): Promise<MatchViewModel[] | null | undefined> => {
      return await api
        .v1MatchsSaveDraftCreate(criteria, requestParams)
        .then(async (resp) => {
          return resp?.data.result as MatchViewModel[];
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    deleteMatchById: async (
      matchId: number
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsDelete(matchId, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    invertMatchById: async (
      matchId: number
    ): Promise<MatchViewModel | undefined> => {
      return await api
        .v1MatchsInvertCreate(matchId, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
        
    generateDraftGroup: async (params: V1MatchsGenerateDraftGroupDetailParams): Promise<MatchViewModel[] | null | undefined> => {
        return await api.v1MatchsGenerateDraftGroupDetail(params, {...requestParams, 
            paramsSerializer: params => {
              return qs.stringify(params)
            }})
            .then(async (resp) => {
                if(resp.data.result)
                    return resp.data.result;
                else
                    return [];
            })
            .catch(async (err) => {
                if(err?.response?.data?.message)
                    enqueueSnackbar(err.response.data.message, {
                    variant: 'error',
                    autoHideDuration: 3000, className: "snackbar" 
                    });
        
                throw err;
            })
    },
    
    saveDraftGroup: async (params: CreateMatchCriteria[][]): Promise<any> => {
        return await api.v1MatchsSaveDraftGroupsCreate(params, requestParams)
            .then(async (resp) => {
            })
            .catch(async (err) => {
                if(err?.response?.data?.message)
                    enqueueSnackbar(err.response.data.message, {
                    variant: 'error',
                    autoHideDuration: 3000, className: "snackbar" 
                    });
        
                throw err;
            })
    },
    
    generateRandomDraftGroup: async (competitionId: number): Promise<MatchViewModel[][] | null | undefined> => {
        return await api.v1MatchsGenerateRandonDraftGroupsDetail(competitionId, requestParams)
            .then(async (resp) => {
              if(resp.data.result)
                  return resp.data.result;
              else
                  return [];
            })
            .catch(async (err) => {
                if(err?.response?.data?.message)
                    enqueueSnackbar(err.response.data.message, {
                    variant: 'error',
                    autoHideDuration: 3000, className: "snackbar" 
                    });
        
                throw err;
            })
    },

    getMatchOverview: async (
      matchId: number
    ): Promise<MatchOverviewViewModel | null | undefined> => {
      return await api
        .v1MatchsOverviewDetail(matchId, requestParams)
        .then(async (resp) => {
          return resp?.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },
  };
};

import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { UserViewModel } from "../../apis/data-contracts";
import { AuthReducerState } from "../reducers/authReducer";

export function useAuthAction() {
  const dispatch = useDispatch();

  return {
    loginAction: (authReducerState: AuthReducerState) => {
      dispatch({
        type: "LOGIN",
        data: authReducerState
      });
    },
    logoutAction: () => {
      dispatch({ type: "LOGOUT" });
    },
  };
}

import { Button, Icon, Paper, PaperProps, Typography } from '@mui/material';
import { MButtonWithText } from '../@material-extend';

interface SearchNotFoundProps extends PaperProps {
  searchQuery?: string;
  component?:any,
  to?:any
  icon?:any
  addButton?: Boolean
  
}

export default function SearchNotFound({ searchQuery = '',component,to, icon, addButton,  ...other }: SearchNotFoundProps) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {addButton ?
          (<>Nenhum resultado encontrado até o momento, por favor, clique no botão  
            <MButtonWithText style={{marginLeft:5, marginRight:5}} variant="contained" component={component} to={to} startIcon={icon}>
                Adicionar novo
            </MButtonWithText> 
            para adicionar primeiro
          </>)
          : (<>Nenhum resultado encontrado até o momento</>)
        }
      </Typography>
    </Paper>
  );
}

import { SxProps } from "@mui/system";
import { ThemeReducerState } from "../../redux/reducers/themeReducer";
import { useSelector } from "react-redux";
import { TextInput } from "../../components/TextInput";
import { TextInputProps } from "../TextInput/TextInput";

export type MTextInputProps = TextInputProps & {sx?: SxProps};

export default function MTextInput({ children, ...other }: MTextInputProps) {
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state?.theme);
  const style = selectedTheme === "dark" 
    ? {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #161C24 inset',
          WebkitTextFillColor: '#fff',
        },
      },
      backgroundColor:'transparent',
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiOutlinedInput-root': {

        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        },
      },
    } 
    : undefined;


  return(
    <TextInput
      {...other}
      sx={{...style}}
      fullWidth>
        {children}
    </TextInput>
  );
}
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { AuthReducerState } from '../../redux/reducers/authReducer';
import { useSelector } from 'react-redux';
import { useUserService } from '../../services/api/user/UserService';
import { PATH_MANAGER } from '../../routes/paths';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 21;
const APP_BAR_DESKTOP = 30;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up('sm')]: {
    paddingTop: APP_BAR_DESKTOP + 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { isRegistrationOk } = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  const [didMount, setDidMount] = useState<boolean>(false);

  useEffect(() => {
    if(!didMount) return;
  
    if(!isRegistrationOk){
      navigate(PATH_MANAGER.settings.user);
    }    
  }, [didMount]);

  useEffect(() => {
    if(!didMount) setDidMount(true);
  }, []);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_SETTINGS = '/settings';
const ROOTS_COMPETITION = '/championship';
const ROOTS_COMPETITIONGROUP = '/competitions';
const ROOTS_TEAMS = '/championship/team';
const ROOTS_PLAYERS = '/championship/team/player';
const ROOTS_MATCHES = '/championship/match';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  verifyToken: path(ROOTS_AUTH, '/verify'),
  verifyEmail: path(ROOTS_AUTH, '/resetPassword'),
  register: path(ROOTS_AUTH, '/register'),
  changePassword: path(ROOTS_AUTH, '/changePassword')
};

export const PATH_MANAGER = {
  root: '/',
  competitionGroups: {
    root: path('',ROOTS_COMPETITIONGROUP),
    new: path(ROOTS_COMPETITIONGROUP, '/new'),
    edit: path(ROOTS_COMPETITIONGROUP, '/edit')
  },
  settings: {
    user: path(ROOTS_SETTINGS, '/user')
  },
  competition: {
    list: ROOTS_COMPETITION,
    new: path(ROOTS_COMPETITION, '/new'),
    edit: path(ROOTS_COMPETITION, '/edit'),
    team: {
      list: ROOTS_TEAMS,
      add: path(ROOTS_TEAMS, '/add'),
      edit: path(ROOTS_TEAMS, '/edit'),
      new: path(ROOTS_TEAMS, '/new'),
      player: {
        list: ROOTS_PLAYERS,
        new: path(ROOTS_PLAYERS, '/new'),
        edit: path(ROOTS_PLAYERS, '/edit')
      }
    },
    match: {
      list: ROOTS_MATCHES,
      new: path(ROOTS_MATCHES, '/new'),
      edit: path(ROOTS_MATCHES, '/edit'),
      draft: path(ROOTS_MATCHES, '/draft'),
      groupDraft: path(ROOTS_MATCHES, '/draft/group'),
      summary: path(ROOTS_MATCHES, '/summary')
    }
  },
};


// export const PATH_BACKOFFICE = {
//   root: ROOTS_DASHBOARD,
//   dashboard: {
//     root: ROOTS_DASHBOARD
//   },
//   package: {
//     root: ROOTS_PACKAGE,
//     list: path(ROOTS_PACKAGE, '/list'),
//     new: path(ROOTS_PACKAGE, '/new'),
//     view: path(ROOTS_PACKAGE, '/view'),
//     edit: path(ROOTS_PACKAGE, '/edit'),
//     content: {
//       root: path(ROOTS_PACKAGE, '/content/'),
//       list: path(ROOTS_PACKAGE, '/content/:packageId')
//     },
//   }
// };

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export function getKeyByValue<T extends object>(value: string, resourceType: T) {
  const indexOfS = Object.values(resourceType).indexOf(value as unknown as T);

  const key = Object.keys(resourceType)[indexOfS];

  return key;
}

export function getIndexOfEnumValue<T extends object>(value: string, resourceType: T) {
  return Object.values(resourceType).indexOf(value as unknown as T);
}

export function getEnumValueByKeyString<T extends object>(key: string, resourceType: T) {
  const enumValue = resourceType[key as keyof typeof resourceType];

  return enumValue;
}
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// components
import LoadingScreen from '../components/LoadingScreen';
import { UrlTypeEnum } from '../utils/Classes/enums/UrlTypeEnum';
import MatchSummary from '../pages/match/MatchSummary';
import PlayerEdit from '../pages/player/PlayerEdit';


const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...( {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', 
      element: (<Navigate to='/competitions' replace />),
      children:[
        {
          path: 'competitions', 
          element: (<CompetitionGroups />)
        }
      ]
    },
    {
      path: 'auth',
      children: [
        { path: 'login', element: (<GuestGuard> <Login /> </GuestGuard>) },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'resetPassword', element: VerifyEmail({urlType: UrlTypeEnum.ForgotPassword}) },
        { path: 'register', element: VerifyEmail({urlType: UrlTypeEnum.Register}) },
        { path: 'verify', element: <VerifyToken /> },
        { path: 'changePassword', element: <ChangePassword /> },
      ]
    },
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <UserSettings />
        </AuthGuard>
      ),
      children: [
        { path: 'user', element: <UserSettings /> },
      ]
    },
    { path: 'competitions', 
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children:[
        {
          path: '', 
          element: (<CompetitionGroups />)
        },
        {
          path: 'new', 
          element: (<CompetitionGroupsNew />)
        },
        {
          path: 'edit', 
          element: (<CompetitionGroupsEdit />)
        }
      ]
    },
    {
      path: 'championship',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { 
          path: '', 
          element: <CompetitionList /> 
        },
        {
          path: 'new', 
          element: (<CompetitionNew />)
        },
        {
          path: 'edit', 
          element: (<CompetitionEdit />)
        },
        {
          path: 'team',
          children: [
            { 
              path: '',  
              element: <TeamList />
            },
            {
              path: 'add', 
              element: (<TeamAdd />)
            },
            {
              path: 'new', 
              element: (<TeamNew />)
            },
            {
              path: 'edit', 
              element: (<TeamEdit />)
            },
            {
              path: 'player',
              children: [
                { 
                  path: '',  
                  element: <PlayerList />
                },
                // {
                //   path: 'new', 
                //   element: (<CompetitionNew />)
                // },
                {
                  path: 'edit', 
                  element: <PlayerEdit />
                }
              ]
            }
          ]
        },
        {
          path: 'match',
          children: [
            { 
              path: '',  
              element: <MatchList />
            },
            {
              path: 'new', 
              element: (<MatchNew />)
            },
            {
              path: 'edit', 
              element: (<MatchEdit />)
            },
            {
              path: 'draft', 
              element: (<MatchDraft />)
            },
            {
              path: 'draft/group', 
              element: (<MatchGroupDraftList />)
            },
            {
              path: 'summary', 
              element: (<MatchSummary />)
            },
          ]
        }
      ]
    },
    
    // {
    //   path: 'package',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   // children: [
    //   //   { path: '', element: <PackageList /> },
    //   //   { path: 'list', element: <PackageList /> },
    //   //   { path: 'new', element: <PackageNew /> },
    //   //   { path: 'view/:packageId', element: <PackageView /> },
    //   //   { path: 'edit/:packageId', element: <PackageEdit /> },
    //   //   {path: 'content',
    //   //     children: [
    //   //       {path: ':packageId', element: <ContentList />}
    //   //     ]}
    //   // ]

    // },
    
    // Main Routes
    {
      path: '*',
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]
  );
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const VerifyEmail = Loadable(lazy(() => import('../pages/authentication/VerifyEmail')));
const VerifyToken = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const UserSettings = Loadable(lazy(() => import('../pages/settings/UserSettings')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));


const TeamList = Loadable(lazy(() => import('../pages/team/TeamList')));
const TeamAdd = Loadable(lazy(() => import('../pages/team/TeamAdd')));
const TeamNew = Loadable(lazy(() => import('../pages/team/TeamNew')));
const TeamEdit = Loadable(lazy(() => import('../pages/team/TeamEdit')));



const PlayerList = Loadable(lazy(() => import('../pages/player/PlayerList')));
// const CompetitionNew = Loadable(lazy(() => import('../pages/competition/CompetitionNew')));
// const PlayerEdit = Loadable(lazy(() => import('../pages/player/PlayerEdit')));


const MatchList = Loadable(lazy(() => import('../pages/match/MatchList')));
const MatchNew = Loadable(lazy(() => import('../pages/match/MatchNew')));
const MatchEdit = Loadable(lazy(() => import('../pages/match/MatchEdit')));
const MatchDraft = Loadable(lazy(() => import('../pages/match/MatchDraftList')));
const MatchGroupDraftList = Loadable(lazy(() => import('../pages/match/MatchGroupDraftList')));


const CompetitionList = Loadable(lazy(() => import('../pages/competition/CompetitionList')));
const CompetitionNew = Loadable(lazy(() => import('../pages/competition/CompetitionNew')));
const CompetitionEdit = Loadable(lazy(() => import('../pages/competition/CompetitionEdit')));

const CompetitionGroups = Loadable(lazy(() => import('../pages/competitionGroups/CompetitionGroups')));
const CompetitionGroupsNew = Loadable(lazy(() => import('../pages/competitionGroups/CompetitionGroupsNew')));
const CompetitionGroupsEdit = Loadable(lazy(() => import('../pages/competitionGroups/CompetitionGroupsEdit')));
//const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
// const PackageNew = Loadable(lazy(() => import('../pages/package/PackageNew')));
// const PackageEdit = Loadable(lazy(() => import('../pages/package/PackageEdit')));
// const PackageView = Loadable(lazy(() => import('../pages/package/PackageView')));
// const PackageList = Loadable(lazy(() => import('../pages/package/PackageList')));


// const ContentList = Loadable(lazy(() => import('../pages/package/content/ContentList')));

// Errors
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
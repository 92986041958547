import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import enUSLocales from './enUS.json';
import ptBRLocales from './ptBR.json';
import esESLocales from './esES.json';
import { LanguageReducerState } from '../redux/reducers/languageReducer';
import { getEnumValueByKeyString, getKeyByValue } from '../utils/enums';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import moment from 'moment';

const standardLanguage = getKeyByValue('Português', LanguageReducerState);

const resources = {
  enUS: {
    competitionManager:enUSLocales
  },
  esES: {
    competitionManager: esESLocales
  },
  ptBR: {
    competitionManager: ptBRLocales,
   
  }
}

export const setGlobalLanguage = (language: LanguageReducerState) => {
  localStorage.setItem('i18nextLng', language)
  i18n.changeLanguage(language);
  setMomentLanguage(language);
}


export const setMomentLanguage = (language: LanguageReducerState) => {
  localStorage.setItem('i18nextLng', language);
  i18n.changeLanguage(language);
  
  let convertedLanguage = getEnumValueByKeyString(language ?? '', LanguageReducerState);
  let selectedLanguage = 'en';
  
  if(convertedLanguage === LanguageReducerState.ptBR)
  selectedLanguage = 'pt-br';
  else if (convertedLanguage === LanguageReducerState.esES)
  selectedLanguage = 'es';
  else
  selectedLanguage = 'en';

  moment.locale(selectedLanguage);
}

const lng = localStorage.getItem('i18nextLng') || standardLanguage;

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: resources,
    lng: lng,
    fallbackLng: standardLanguage,
    debug: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    }
  });


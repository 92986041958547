// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Grid } from '@mui/material';

// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../pages/authentication/components/LoginForm';
import Logo from '../../components/Logo';
import { useTranslation } from 'react-i18next';
import { ThemeReducerState } from '../../redux/reducers/themeReducer';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Loading } from '../../components/Loading';
import { useLocation } from 'react-router-dom';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '30%',
  //maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  //padding: theme.spacing(12, 0)
}));

interface LoginValues{
  login: string
}

export default function Login() {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state?.theme);
  const loginValues = useLocation().state as LoginValues;

  return (
    <Loading loading={loading}>
      <RootStyle title={t('competitionManager:signIncompetitionManager:welcome')}>
        <Grid container>
        <Grid item md={4} alignContent={'center'}>

        <MHidden width='mdDown'>
          {/* <SectionStyle> */}
          <Logo size={'large'} sx={{ borderRadius: 5, width: '90%', padding:'0 0', margin: 'auto' }} type='full' />
          {/* </SectionStyle> */}
        </MHidden>
        </Grid>
        <Grid item md={8} alignContent={'center'}>
        <Container maxWidth='sm'>
          <ContentStyle>
              <Stack direction='row' alignItems='center' alignContent={'center'}  mb={3}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='h4' gutterBottom sx={{
                    background: '#562B7F',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}>
                    {t('competitionManager:signIncompetitionManager:welcome')}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{t('competitionManager:signIncompetitionManager:signInDetails')}</Typography>
                </Box>
              </Stack>
              <LoginForm setLoading={setLoading} initialLogin={loginValues?.login ?? ''} />
          </ContentStyle>
        </Container>
        </Grid>
        </Grid>

      </RootStyle>
    </Loading>
  );
}

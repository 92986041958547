import React, {useEffect, useLayoutEffect, useState} from 'react';
import * as Yup from 'yup';

// material
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

// components
import { useTranslation } from "react-i18next";

// icons
import { MButtonWithText } from "../../../components/@material-extend";
import MTextField from '../../../components/@material-extend/MTextField';
import { UpdatePlayerCompetitionRegisterCriteria } from '../../../apis/data-contracts';
import { Form, FormikContextType, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { MiscellaneousReducerState } from '../../../redux/reducers/miscellaneousReducer';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextInput } from '../../../components/TextInput';
import { useSnackbar } from 'notistack';
import { usePlayerService } from '../../../services/api/player/PlayerService';


type PlayerFormProps = {
  formMode: 'New' | 'Edit' | 'View';
  playerName: string;
  playerEmail: string;
  initialPlayerValues?: UpdatePlayerCompetitionRegisterCriteria;
  defaultIsPlayer: string;
  setDefaultIsPlayer: (value: string) => void;
  setLoading: (value: boolean) => void;
}


export function PlayerForm(props: PlayerFormProps) {
  const { formMode } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const misc = useSelector((state: { miscellaneous: MiscellaneousReducerState }) => state?.miscellaneous);
  const { updatePlayer } = usePlayerService();
  const [openDateModal, setOpenDateModal] = useState<boolean>(false);



  const PlayerEditFormSchema = Yup.object().shape({
    name: Yup.string().required(t(`competitionManager:fieldRequired`)),
    nickname: Yup.string().required(t(`competitionManager:fieldRequired`)),
  });
  
  const formik = useFormik<UpdatePlayerCompetitionRegisterCriteria>({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      competitionId: misc.selectedCompetition?.id,
      teamId: misc.selectedTeam?.id,
      playerId: props.initialPlayerValues?.playerId ?? 0,
      nickName: props.initialPlayerValues?.nickName ?? '',
      doc: props.initialPlayerValues?.doc ?? '',
      docCbf: props.initialPlayerValues?.docCbf ?? '',
      docCbfs: props.initialPlayerValues?.docCbfs ?? '',
      docFederacao: props.initialPlayerValues?.docFederacao ?? '',
      docCpf: props.initialPlayerValues?.docCpf ?? '',
      birthday: props.initialPlayerValues?.birthday ?? '',
      playerNumberDefault: props.initialPlayerValues?.playerNumberDefault ?? '',
      position: props.initialPlayerValues?.position ?? '',
      coach: props.initialPlayerValues?.coach ?? '',
    },
    validationSchema: PlayerEditFormSchema,
    onSubmit: async (values) => {
      // props.setLoading(true);

      // await editRegisterPlayerFromCompetition(values).then((data) => {
      //   enqueueSnackbar(t(`competitionManager:teamEdited`), { variant: 'success', autoHideDuration: 3000, className: "snackbar"  });
      //   alert('deu bom demais');
      //   location.reload();
      //   // navigate(PATH_MANAGER.competition.team.player.list);
      // }).catch(() => {
      //   props.setLoading(false);
      //   alert('deu ruim');
      // });
    }
  });
 
  const { touched, errors, getFieldProps, setFieldValue,  handleSubmit, values } = formik;

  const handleEditPlayer = async () => {
    props.setLoading(true);
    await updatePlayer(values).then((data) => {
      enqueueSnackbar(t(`competitionManager:teamEdited`), { variant: 'success', autoHideDuration: 3000, className: "snackbar"  });
      location.reload();
    }).catch(() => {
      props.setLoading(false);
    });
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Stack spacing={5}>
            <Grid container sx={{mt: 5}}>
              <Grid container spacing={3} mt={5}>

                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View' || formMode === 'Edit'}
                    label={t('competitionManager:fields:teamName')}
                    value={props.playerName}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View' || formMode === 'Edit'}
                    label={t('competitionManager:fields:email')}
                    value={props.playerEmail}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View'}
                    label={t('competitionManager:fields:nickname')}
                    {...getFieldProps('nickName')}
                    onChange={(e) => setFieldValue('nickName', e.target.value?.substring(0, 80))}
                    error={Boolean(touched.nickName && errors.nickName)}
                    helperText={touched.nickName && errors.nickName}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View'}
                    label={t('competitionManager:fields:rg')}
                    {...getFieldProps('doc')}
                    onChange={(e) => setFieldValue('doc', e.target.value)}
                    error={Boolean(touched.doc && errors.doc)}
                    helperText={touched.doc && errors.doc}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <TextInput
                    fullWidth 
                    disabled={formMode === 'View'}
                    label={t('competitionManager:fields:cpf')}
                    {...getFieldProps('docCpf')}
                    onChange={(e) => setFieldValue('docCpf', e.target.value)}
                    error={Boolean(touched.docCpf && errors.docCpf)}
                    helperText={touched.docCpf && errors.docCpf}
                    mask={'cpf'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View'}
                    label={t('competitionManager:fields:docFederacao')}
                    {...getFieldProps('docFederacao')}
                    onChange={(e) => setFieldValue('docFederacao', e.target.value)}
                    error={Boolean(touched.docFederacao && errors.docFederacao)}
                    helperText={touched.docFederacao && errors.docFederacao}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View'}
                    label={t('competitionManager:fields:docCbf')}
                    {...getFieldProps('docCbf')}
                    onChange={(e) => setFieldValue('docCbf', e.target.value)}
                    error={Boolean(touched.docCbf && errors.docCbf)}
                    helperText={touched.docCbf && errors.docCbf}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <MTextField
                    disabled={formMode === 'View'}
                    label={t('competitionManager:fields:docCbfs')}
                    {...getFieldProps('docCbfs')}
                    onChange={(e) => setFieldValue('docCbfs', e.target.value)}
                    error={Boolean(touched.docCbfs && errors.docCbfs)}
                    helperText={touched.docCbfs && errors.docCbfs}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={openDateModal}
                      onClose={() => setOpenDateModal(false)}
        
                      label={t('competitionManager:fields:dateOfBirth')}
                      {...getFieldProps(`birthday`)}
                      value={moment(values?.birthday)}
                      maxDate={moment().subtract(16, 'years')}
                      slotProps={{
                        textField: {
                          ...(
                            <MTextField
                              onClick={(e) => setOpenDateModal(true)}                      
                              error={Boolean(touched.birthday && errors.birthday)}
                              helperText={touched.birthday && errors.birthday}
                              inputProps={{ readOnly: true }}
                              fullWidth/>
                        ).props}}
                      }
                      onChange={(newValue) => {
                        setFieldValue(`birthday`, newValue === null ? values?.birthday : moment(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                </Grid>



                <Grid item sm={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="is-player">{`${t('competitionManager:summaryOccurences:playerName')}`}</InputLabel>
                    <Select
                      labelId="is-player"
                      value={props.defaultIsPlayer}
                      label={t('competitionManager:summaryOccurences:playerName')}
                      onChange={(e) => {
                        props.setDefaultIsPlayer(`${e.target.value}`)
                        setFieldValue('coach', `${e.target.value}` === 'Não' ? 'Sim' : 'Não')
                        if(`${e.target.value}` === 'Sim') setFieldValue('position', 'Goleiro');
                        else setFieldValue('position', 'Técnico')
                      }}
                    >
                      <MenuItem value={'Sim'}>{t('competitionManager:summaryApprove:yes')}</MenuItem>
                      <MenuItem value={'Não'}>{t('competitionManager:summaryApprove:no')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="position-name">{`${t('competitionManager:fields:position')}`}</InputLabel>
                      { props.defaultIsPlayer === 'Sim' ? (
                        <>
                          {
                            misc.selectedCompetition?.modality === 'Futsal' && (
                              <Select
                                labelId="position-name"                          
                                value={values.position}
                                label={t('competitionManager:fields:position')}
                                onChange={(e) => {setFieldValue('position', `${e.target.value}`)}}
                              >
                                <MenuItem value={'Goleiro'}>{t('competitionManager:positions:goalkeeper')}</MenuItem>
                                <MenuItem value={'Ala'}>{t('competitionManager:positions:winger')}</MenuItem>
                                <MenuItem value={'Pivô'}>{t('competitionManager:positions:pivot')}</MenuItem>
                                <MenuItem value={'Fixo'}>{t('competitionManager:positions:fixed')}</MenuItem>
                              </Select>
                            )
                          }
                          {
                            misc.selectedCompetition?.modality === 'Futebol de campo' && (                                 
                              <Select
                                labelId="position-name"                          
                                value={values.position}
                                label={t('competitionManager:fields:position')}
                                onChange={(e) => {setFieldValue('position', `${e.target.value}`)}}
                              >
                                <MenuItem value={'Goleiro'}>{t('competitionManager:positions:goalkeeper')}</MenuItem>
                                <MenuItem value={'Zagueiro'}>{t('competitionManager:positions:defender')}</MenuItem>
                                <MenuItem value={'Lateral'}>{t('competitionManager:positions:full-back')}</MenuItem>
                                <MenuItem value={'Volante'}>{t('competitionManager:positions:defensiveMidfielder')}</MenuItem>
                                <MenuItem value={'Meio Campo'}>{t('competitionManager:positions:midfielder')}</MenuItem>
                                <MenuItem value={'Atacante'}>{t('competitionManager:positions:Atacante')}</MenuItem>
                                <MenuItem value={'Centroavante'}>{t('competitionManager:positions:centerForward')}</MenuItem>
                              </Select>
                            )
                          }
                          {                                
                            misc.selectedCompetition?.modality === 'Futebol 7' && (
                              <Select
                                labelId="position-name"                          
                                value={values.position}
                                label={t('competitionManager:fields:position')}
                                onChange={(e) => {setFieldValue('position', `${e.target.value}`)}}
                              >
                                <MenuItem value={'Goleiro'}>{t('competitionManager:positions:goalkeeper')}</MenuItem>
                                <MenuItem value={'Zagueiro'}>{t('competitionManager:positions:defender')}</MenuItem>
                                <MenuItem value={'Lateral'}>{t('competitionManager:positions:full-back')}</MenuItem>
                                <MenuItem value={'Meio Campo'}>{t('competitionManager:positions:midfielder')}</MenuItem>
                                <MenuItem value={'Atacante'}>{t('competitionManager:positions:Atacante')}</MenuItem>
                              </Select>
                            )
                          }
                          {
                            misc.selectedCompetition?.modality === 'Society' && (
                              <Select
                                labelId="position-name"                          
                                value={values.position}
                                label={t('competitionManager:fields:position')}
                                onChange={(e) => {setFieldValue('position', `${e.target.value}`)}}
                              >
                                <MenuItem value={'Goleiro'}>{t('competitionManager:positions:goalkeeper')}</MenuItem>
                                <MenuItem value={'Zagueiro'}>{t('competitionManager:positions:defender')}</MenuItem>
                                <MenuItem value={'Lateral'}>{t('competitionManager:positions:full-back')}</MenuItem>
                                <MenuItem value={'Meio Campo'}>{t('competitionManager:positions:midfielder')}</MenuItem>
                                <MenuItem value={'Atacante'}>{t('competitionManager:positions:Atacante')}</MenuItem>
                              </Select>
                            )
                          }
                          {
                            misc.selectedCompetition?.modality === 'Futebol de praia' && (
                              <Select
                                labelId="position-name"                          
                                value={values.position}
                                label={t('competitionManager:fields:position')}
                                onChange={(e) => {setFieldValue('position', `${e.target.value}`)}}
                              >
                                <MenuItem value={'Goleiro'}>{t('competitionManager:positions:goalkeeper')}</MenuItem>
                                <MenuItem value={'Zagueiro'}>{t('competitionManager:positions:defender')}</MenuItem>
                                <MenuItem value={'Atacante'}>{t('competitionManager:positions:Atacante')}</MenuItem>
                              </Select>
                            )
                          }
                        </>
                      ) : (
                        <Select
                          labelId="position-name"                          
                          value={values.position}
                          label={t('competitionManager:fields:position')}
                          onChange={(e) => {setFieldValue('position', `${e.target.value}`)}}
                        >
                          <MenuItem value={'Representante'}>{t('competitionManager:positions:representative')}</MenuItem>
                          <MenuItem value={'Médico'}>{t('competitionManager:positions:doctor')}</MenuItem>
                          <MenuItem value={'Técnico'}>{t('competitionManager:positions:coach')}</MenuItem>
                          <MenuItem value={'Fisioterapeuta'}>{t('competitionManager:positions:physiotherapist')}</MenuItem>
                          <MenuItem value={'Analista Desempenho'}>{t('competitionManager:positions:performanceAnalyst')}</MenuItem>
                          <MenuItem value={'Massagista'}>{t('competitionManager:positions:masseur')}</MenuItem>
                          <MenuItem value={'Preparador Físico'}>{t('competitionManager:positions:physicalTrainer')}</MenuItem>
                          <MenuItem value={'Supervisor'}>{t('competitionManager:positions:supervisor')}</MenuItem>
                          <MenuItem value={'Auxiliar Técnico'}>{t('competitionManager:positions:technicalAssistant')}</MenuItem>
                          <MenuItem value={'Preparador Goleiro'}>{t('competitionManager:positions:goalkeeperTrainer')}</MenuItem>
                        </Select>
                      )
                      }
                  </FormControl>
                </Grid>


              <Grid item sm={12} md={3}>
                <TextField 
                  fullWidth
                  label={t('competitionManager:summaryOccurences:playerNumber')} 
                  variant='outlined'
                  {...getFieldProps('playerNumberDefault')}
                  onChange={(e) => setFieldValue('playerNumberDefault', e.target.value)}
                  error={Boolean(touched.playerNumberDefault && errors.playerNumberDefault)}
                  helperText={touched.playerNumberDefault && errors.playerNumberDefault}

                  type="number"
                  InputProps={{
                      inputProps: { 
                          max: 100, min: 10 
                      }
                  }}
                  onInput={(e) => {
                    const numericValue = parseFloat(e.target.value);

                    // Check if the numeric value exceeds the max value (100)
                    if (!isNaN(numericValue) && numericValue > 999) {
                      e.target.value = "999";
                    }

                    // Check if the numeric value is below the min value (0)
                    if (!isNaN(numericValue) && numericValue < 1) {
                      e.target.value = "1";
                    }
                  }}
                />
                </Grid>

                <Grid item sm={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel id="is-coach-too">{`${t('competitionManager:fields:coach')}`}</InputLabel>
                    <Select
                      disabled={props.defaultIsPlayer === 'Não'}
                      labelId="is-coach-too"
                      {...getFieldProps('coach')}
                      onChange={(e) => setFieldValue('coach', props.defaultIsPlayer === 'Não' ? 'Não' : `${e.target.value}`)}
                      label={`${t('competitionManager:fields:coach')}`}
                    >
                      <MenuItem value={'Sim'}>{t('competitionManager:summaryApprove:yes')}</MenuItem>
                      <MenuItem value={'Não'}>{t('competitionManager:summaryApprove:no')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </Grid>



            <Grid container>
              <Grid item xs={12} sm={12}>
                <Divider color={"purple"} />
                <Box sx={{ pt: 3 }} flexDirection='row' display='flex' justifyContent={'flex-end'}>
                  <Grid container justifyContent={'flex-end'} item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                      <MButtonWithText
                        disabled={formMode === 'View'}
                        size="large"
                        variant="contained"
                        fullWidth
                        type='button'
                        onClick={() => handleEditPlayer()}
                        startIcon={<Icon icon={"ri:save-line"} width={20} height={20} />}
                      >
                        {t('competitionManager:save')}
                      </MButtonWithText>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid >
          </Stack>
        </Form>
      </FormikProvider>
    </>
  )
}

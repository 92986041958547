// routes
import { PATH_MANAGER } from '../../routes/paths';

// components
// import SvgIconStyle from '../../components/SvgIconStyle';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { MiscellaneousReducerState } from '../../redux/reducers/miscellaneousReducer';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );


export default function AdminSidebarConfig() { 
  const { t } = useTranslation();
  const misc = useSelector((state: { miscellaneous: MiscellaneousReducerState }) => state?.miscellaneous);

  let configs = [{
    subheader:  misc?.selectedCompetitionGroup?.name ?? '',
    itemPath: PATH_MANAGER.competition.list,
    items: [
      {
        title: t('competitionManager:competitions'),
        path: PATH_MANAGER.competitionGroups.root,
        icon: <Icon icon={'mdi:tournament'} style={{ fontSize: '25px' }}/>
      }
    ]
  }];

  if((misc.selectedCompetition?.id ?? 0) > 0) {
    configs = configs.concat({
      subheader: misc?.selectedCompetition?.name ?? '',
      itemPath: PATH_MANAGER.competition.team.list,
      items: [
        {
          title: t('competitionManager:championships'),
          path: PATH_MANAGER.competition.list,
          icon: <Icon icon="bi:trophy" style={{ fontSize: '25px' }} />
        }
      ]});
    }

    if((misc.selectedTeam?.id ?? 0) > 0){
      configs = configs.concat({
        subheader: misc?.selectedTeam?.name ?? '',
        itemPath: PATH_MANAGER.competition.team.player.list,
        items: [
          {
            title: t('competitionManager:teams'),
            path: PATH_MANAGER.competition.team.list,
            icon: <Icon icon="ri:shield-line" style={{ fontSize: '25px' }} />
          }
        ]});
      }

    if((misc.selectedPlayer?.playerId ?? 0) > 0){
      configs = configs.concat({
        subheader: misc?.selectedPlayer?.nickname ?? '',
        itemPath: PATH_MANAGER.competition.team.player.edit,
        items: [
          {
            title: t('competitionManager:fields:player'),
            path: PATH_MANAGER.competition.team.player.edit,
            icon: <Icon icon="carbon:soccer" style={{ fontSize: '25px' }} />
          }
        ]});
      }

      if((misc.selectedMatch?.matchId ?? 0) > 0){
        configs = configs.concat({
          subheader: `${misc?.selectedMatch?.teamHomeName} x ${misc?.selectedMatch?.teamAwayName}` ?? '',
          itemPath: PATH_MANAGER.competition.match.summary,
          items: [
            {            
              title: t('competitionManager:match'),
              path: PATH_MANAGER.competition.match.list,
              icon: <Icon icon="fluent-emoji-high-contrast:soccer-ball" style={{ fontSize: '25px' }} />
            }
          ]});
        }

  return configs;
}

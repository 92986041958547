import { useSelector } from 'react-redux';
import { Api } from '../../../apis/Api';
import { AuthReducerState } from '../../../redux/reducers/authReducer';
import { useSnackbar } from 'notistack';
import { useAuthAction } from '../../../redux/actions/authAction';
import { AxiosResponse } from 'axios';

import {
  CompetitionTeamPlayerCriteria,
  CompetitionTeamPlayerSubscribeViewModelIQueryableIResult,
  PlayerViewModelIQueryableIResult,
  TeamViewModel,
  V1CompetitionsTeamsPlayersDetailParams,
  CompetitionTeamPlayerSubscribeViewModel,
  PlayerViewModelICollectionIResult,
  PlayerViewModelIResult,
  UpdatePlayerCompetitionRegisterCriteria,
  CompetitionTeamPlayerSubscribeViewModelIResult,
} from '../../../apis/data-contracts';

export const usePlayerService = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loginAction } = useAuthAction();
  const api = new Api({
    baseURL: `${process.env.REACT_APP_COMPETITIONMANAGERAPI_BASEURL}`,
  });

  const auth = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  const requestParams = {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  };

  return {

    listPlayers: async (
      query: V1CompetitionsTeamsPlayersDetailParams
    ): Promise<
      AxiosResponse<CompetitionTeamPlayerSubscribeViewModelIQueryableIResult>
    > => {
      return await api
        .v1CompetitionsTeamsPlayersDetail(query, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    listPlayersOnCompetition: async (
      id: number,
      teamId: number
    ): Promise<AxiosResponse<PlayerViewModelIQueryableIResult>> => {
      return await api
        .v1CompetitionsTeamsPlayersToSubscribeDetail(id, teamId, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    registerPlayerFromCompetition: async (
      data: CompetitionTeamPlayerCriteria
    ): Promise<TeamViewModel | undefined> => {
      return await api
        .v1PlayersRegisterForCompetitionCreate(data, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    editRegisterPlayerFromCompetition: async (
      data: CompetitionTeamPlayerCriteria
    ): Promise<TeamViewModel | undefined> => {
      return await api
        .v1PlayersEditRegisterInCompetitionCreate(data, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    updatePlayer: async (
      data: UpdatePlayerCompetitionRegisterCriteria
    ): Promise<CompetitionTeamPlayerSubscribeViewModel | undefined> => {
      return await api
        .v1PlayersUpdateCompetitionRegistrationUpdate(data, requestParams)
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },

    removePlayerFromCompetition: async (
      playerId: number,
      competitionId: number
    ): Promise<CompetitionTeamPlayerSubscribeViewModel | undefined> => {
      return await api
        .v1PlayersRemoveFromCompetitionDelete(
          playerId,
          competitionId,
          requestParams
        )
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });

          throw err;
        });
    },
    
    removeFromPreRegistration: async (
      playerId: number,
      competitionId: number
    ): Promise<CompetitionTeamPlayerSubscribeViewModel | undefined> => {
      return await api
        .v1PlayersRemoveFromPreRegistrationDelete(
          playerId,
          competitionId,
          requestParams
        )
        .then(async (resp) => {
          return resp.data.result;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },

    listPlayersToInvite: async (competitionId: number, searchTerm: string): Promise<
      AxiosResponse<PlayerViewModelICollectionIResult>
    > => {
      return await api
        .v1PlayersListToInviteForCompetitionDetail({competitionId: competitionId, searchTerm: searchTerm }, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },

    invitePlayers: async (competitionId: number, teamId: number, emails: string[], playersIds: number[], position: string, coach: string): Promise<AxiosResponse<PlayerViewModelICollectionIResult>> => {
      return await api
        .v1CompetitionsInvitePlayerCreate(competitionId, {teamId: teamId, emails: emails, playersId: playersIds, position: position, coach: coach}, requestParams)
        .then(async (resp) => {
          return resp;
        })
        .catch(async (err) => {
          if (err?.response?.data?.message)
            enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
            });
          throw err;
        });
    },

    getPlayerById: async (id: number): Promise<PlayerViewModelIResult> => {
      return await api.v1PlayersDetail(id, requestParams)
        .then(async (resp) => {
            return resp.data;
        })
        .catch(async (err) => {
          if(err?.response?.data?.message)
              enqueueSnackbar(err.response.data.message, {
              variant: 'error',
              autoHideDuration: 3000, className: "snackbar" 
              });
          throw err;
      })
    }



  };
};
